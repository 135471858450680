import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { DEFAULT_CONFIG } from './providers/GameProvider.types';

import GameMachineBg from '../src/assets/gameMachine/background.webp';
import GameMachineMobBg from '../src/assets/gameMachine/background_mob.webp';
import IconsWebp from '../src/assets/gameMachine/icons_sprite_2x.webp';
import ScreenBgWebp from '../src/assets/gameMachine/screen_bg.webp';
import SpinButtonWebp from '../src/assets/buttons/button.webp';
import SpinActiveButtonWebp from '../src/assets/buttons/active_button.webp';
import ScoreBgWebp from '../src/assets/gameMachine/score_bg.webp';
import CupWebp from '../src/assets/buttons/cup.webp';
import HelpButtonWebp from '../src/assets/buttons/help_button.webp';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const isMobile = window.innerWidth < 600 && window.innerHeight < 1000;
const GameMachineBackground = isMobile ? GameMachineMobBg : GameMachineBg;

const IMAGES_TO_LOAD = [
  GameMachineBackground,
  IconsWebp,
  SpinButtonWebp,
  SpinActiveButtonWebp,
  ScoreBgWebp,
  CupWebp,
  HelpButtonWebp,
  ScreenBgWebp,
];

Promise.all([
  ...IMAGES_TO_LOAD.map((imageSrc) => {
    return new Promise((resolve, reject) => {
      const loadImg = new Image();
      loadImg.src = imageSrc;
      loadImg.onload = () => resolve(imageSrc);
      loadImg.onerror = (err) => reject(err);
    });
  }),
])
  .then((allPromises) => {
    //@ts-ignore
    window.webkit?.messageHandlers?.handler?.postMessage('loadFinished');

    root.render(<App config={DEFAULT_CONFIG} />);
  })
  .catch((err) => console.log('Failed to load', err));
