import { useState, ReactNode, createContext, FC } from 'react';
import { ConfigType, GameContextType } from './GameProvider.types';

export const GameContext = createContext<GameContextType | null>(null);

export const GameProvider: FC<{ config: ConfigType; children: ReactNode }> = ({ config, children }) => {
  const [totalWinAmount, setTotalWinAmount] = useState<number>(config.startAmount);
  const [roundNumber, setRoundNumber] = useState<number>(1);
  const [isShowModal, setShowModal] = useState(false);
  const [isShowLanding, setShowLanding] = useState(false);
  const [isRolling, setRolling] = useState(false);
  const [roundWinAmount, setRoundWinAmount] = useState<number | null>(null);
  const [isWinningRound, setWinningRound] = useState(false);

  const onFinish = (matches: number[]) => {
    const totalMatches = matches.reduce((acc, result, resultIndex, arr) => {
      let howManyMatches = 0;
      matches?.forEach((el, elIndex) => {
        if (el === result && elIndex !== resultIndex) {
          howManyMatches += 1;
        }
      });

      return howManyMatches > acc ? howManyMatches : acc;
    }, 0);

    let userWinAmount = config.prizes[totalMatches];

    if (totalMatches > 0) {
      setWinningRound(true);
      setRoundWinAmount(userWinAmount);
      setTotalWinAmount((prev) => prev + userWinAmount);
    }

    setRoundNumber((prev) => (prev += 1));
    // if (
    //   roundNumber === config.firstShow ||
    //   (roundNumber > config.firstShow && (roundNumber - config.firstShow) % config.showAfter === 0)
    // ) {
    //   // facebook and google Lead event
    //   // @ts-ignore
    //   if (window.fbq) {
    //     // @ts-ignore
    //     fbq('track', 'Lead');
    //   }
    //   // @ts-ignore
    //   if (window.gtag) {
    //     // @ts-ignore
    //     gtag('event', 'Lead', { send_to: process.env.REACT_APP_GTAG_EVENTS_ID });
    //   }

    //   if (config.useLanding) {
    //     setShowLanding(true);
    //   } else {
    //     setShowModal(true);
    //   }
    // }
    setRolling(false);
  };

  const onStart = () => {
    setTotalWinAmount((prev) => prev + config.prizes[0]);
    setWinningRound(false);
    setRoundWinAmount(null);
    setRolling(true);
  };

  const value: GameContextType = {
    totalWinAmount,
    onFinish,
    config,
    roundNumber,
    isShowModal,
    isShowLanding,
    setShowModal,
    roundWinAmount,
    onStart,
    isRolling,
    isWinningRound,
  };

  return <GameContext.Provider value={value}>{children}</GameContext.Provider>;
};
